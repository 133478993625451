import React, { Component } from 'react';
import AuthService from "../services/auth";
import authHeader from '../services/auth-header';
import Login from "../components/login";
import './ParticipantUpdate.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import ParticipantExports from './ParticipantExports.js';

export class ParticipantUpdate extends Component {
    static displayName = ParticipantUpdate.name;

    constructor(props) {
        super(props);
        this.state =
        {
            userReady: false,
            currentUser: { username: "" },
            currenttab: 0,
            loading: false,
            currentParticipant: null,
            lastupdatestatus: "",
            message: "Loading..."
        }
        this.handleButton = this.handleButton.bind(this);
        this.handleExportsCallback = this.handleExportsCallback.bind(this);
        this.updateParticipant = this.updateParticipant.bind(this);
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser || currentUser.loggedin === false) {
            alert("ParticipantUpdate - componentDidMount\r\nLogin expired.  Navigate to home page");
            AuthService.logout();
            this.setState({ currentUser: null, userReady: false });
            //this.props.router.navigate("/");
        }
        else {
            this.setState({ currentUser: currentUser, userReady: true })
            let requestOptions =
            {
                method: 'get',
                headers: authHeader()
            };
            fetch('/TAClient/GetParticipant?participant_id=' + currentUser.callerid, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        this.setState({ message: response.statusText });
                        if (response.status === 401) {
                            alert("Login expired.  Reload from " + window.location.host + "/");
                            AuthService.logout();
                            this.props.router.navigate("/");
                        }
                        else throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((dobj) => {
                    this.setState({ loading: false });
                    if (dobj.status) {
                        this.setState({ currentParticipant: dobj, message: "" });
                    }
                    else {
                        this.setState({
                            message: dobj.status_text,
                            currentParticipant: null
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        currentParticipant: null,
                        loading: false
                    });
                });
        }
    }

    handleButton(e) {
        e.preventDefault();
    }

    handleExportsCallback(index, values) {
        var cp = this.state.currentParticipant;
        var cu = this.state.currentUser;
        var eidx = index;
        if (index > 100) {
            values["export_type_id"] = index - 100;
            eidx = this.state.currentParticipant.exportList.length;
            this.state.currentParticipant.exportList.push();
        }

        var ftp_port = 0;
        if (values["ftp_port"] !== null && values["ftp_port"].length > 0 && isNaN(values["ftp_port"]) === false) ftp_port = parseInt(values["ftp_port"], 10);
        var ftp_connection_type_id = 0;
        try {
            if (values["ftp_connection_type_id"] !== null && values["ftp_connection_type_id"].length > 0 && isNaN(values["ftp_connection_type_id"]) === false) ftp_connection_type_id = parseInt(values["ftp_connection_type_id"], 10);

        }
        catch (e) {
            alert(e);
        }

        cp.exportList[eidx].export_method_id = values["export_method_id"];
        cp.exportList[eidx].participant_id = cu.callerid;
        cp.exportList[eidx].deleted_flag = values["deleted_flag"];
        cp.exportList[eidx].enabled_flag = (values["enabled_flag"]?.toLowerCase?.() === 'true'); 
        cp.exportList[eidx].subfolder = values["subfolder"];
        cp.exportList[eidx].export_type_id = values["export_type_id"];
        cp.exportList[eidx].domain = values["domain"] === null ? "" : values["domain"];
        cp.exportList[eidx].username = values["username"] === null ? "" : values["username"];
        cp.exportList[eidx].password = values["password"] === null ? "" : values["password"];
        cp.exportList[eidx].ftp_host = values["ftp_host"] === null ? "" : values["ftp_host"];
        cp.exportList[eidx].ftp_port = ftp_port;
        cp.exportList[eidx].ftp_connection_type_id = ftp_connection_type_id;
        cp.exportList[eidx].receive_summary_flag = (values["receive_summary_flag"]?.toLowerCase?.() === 'true'); 
        cp.exportList[eidx].receive_log_flag = (values["receive_summary_flag"]?.toLowerCase?.() === 'true'); 
        cp.exportList[eidx].filename_format = values["filename_format"] === null ? "" : values["filename_format"];
        cp.exportList[eidx].initial_install_flag = (values["initial_install_flag"]?.toLowerCase?.() === 'true'); 
        this.updateParticipant(cp);


    }

    updateParticipant(cp) {
        this.setState({ updateloading: true });
        const requestOptions =
        {
            method: 'post',
            headers: authHeader(),
            body: JSON.stringify(cp)
        };

        var rst = "Update not performed";
        fetch('/TAClient/UpdateParticipant', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return { "status": false, "status_text": response.status + " " + response.statusText };
                //    if (response.status === 401) {
                //        alert("Login expired.  Reload from " + window.location.host + "/");
                //        AuthService.logout();
                //        //window.location.href = window.location.host + "/";
                //    }
                //    else {
                //        console.log("UpdateParticipant failed: " + response.statusText);
                //        this.setState({ lastupdatestatus: response.statusText, updateloading: false });
                //        return { "status": false, "status_text": response.statusText };
                //    }
                }
                else {
                    return response.json();
                }
            })
            .then((dobj) => {
                var messageText = "Update Successful";
                var titleText = dobj.status_text;
                if (titleText === "Success") {
                    titleText = "Update successful";
                    messageText = "Export method updated correctly";
                    this.setState({ currentParticipant: dobj.updatedParticipant });
                }
                else {
                    messageText = titleText;
                    titleText = "Update failed";
                }
                confirmAlert({
                    title: titleText,
                    message: messageText,
                    buttons: [
                        {
                            label: 'OK'
                        }
                    ]
                });
                this.setState({ lastupdatestatus: rst, updateloading: false });
            });

        return;

    }
    render() {

        if (!this.state.currentUser || this.state.currentUser.loggedin === false) return (<Login />);
        let exportData = <ParticipantExports currentParticipant={this.state.currentParticipant} currentUser={this.state.currentUser} lastupdatestatus={this.state.lastupdatestatus} parentCallback={this.handleExportsCallback} />

        let participantInfoData =
            (this.state.currentParticipant == null ? "" :
                <div className='updateDiv'>
                    <table>
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{this.state.currentParticipant.prt.name}</td>
                            </tr>
                            <tr>
                                <td>API:</td>
                                <td>{this.state.currentParticipant.prt.webapi}</td>
                            </tr>
                            <tr>
                                <td>Receive Summary Messages</td>
                                <td>{this.state.currentParticipant.prt.receive_summary_flag ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td>Receive Log Messages</td>
                                <td>{this.state.currentParticipant.prt.receive_log_flag ? "Yes" : "No"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>);

        let currentParticipantData = (this.state.currentParticipant == null ? "" :
            <Tabs selectedIndex={this.state.currenttab} onSelect={newIndex => this.setState({ currenttab: newIndex, lastupdatestatus: "" })}>

                <TabList>
                    <Tab>Participant</Tab>
                    <Tab>Default Exports</Tab>
                </TabList>
                <TabPanel>{participantInfoData}</TabPanel>
                <TabPanel>{exportData}</TabPanel>
            </Tabs>);

        return (
            <div>
                <h1 id="tableLabel">Participant Settings</h1>
                <div>
                    <div style={{float: "left", width: "1200px"} }>
                        {currentParticipantData}
                    </div>
                </div>
            </div>);

    }
}
export default ParticipantUpdate;