import React, { Component } from 'react';

import Form from 'react-validation/build/form'
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const required = (value) => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return (<div className="alert alert-danger" role="alert">
            This field is required!
        </div>);
    }
};
const integeronly = value => {
    if (value && isNaN(value)) return (<div className="alert alert-danger" role="alert">
        This field must be numeric!
    </div>
    );
}

var posTypes = [
    { posTypeId: 0, posName: "Unused" },
    { posTypeId: 1, posName: "Passport" },
    { posTypeId: 2, posName: "Commander" },
    { posTypeId: 3, posName: "PassportPJR" },
    { posTypeId: 4, posName: "CronySoft" },
    { posTypeId: 5, posName: "SmartDesq" },
    { posTypeId: 6, posName: "NCR" },
    { posTypeId: 7, posName: "Reliant" }
];
export class StorePosTypes extends Component {
    static displayName = StorePosTypes.name;

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeletedFlag = this.handleDeletedFlag.bind(this);
        this.handleRadiantDailyFolders = this.handleRadiantDailyFolders.bind(this);


        this.state =
        {
            message: null,
            redirect: null,
            index: 0,
            passportdeletedflag: "N",
            passportstoreid : 0,
            commanderdeletedflag: "N",
            commanderstoreid: 0,
            smartdesqdeletedflag: "N",
            smartdesqstoreid: 0,
            radiantdailyfolders: true,
            radiantdeletedflag: "N",
            radiantstoreid: 0
        }

    }

    componentDidMount() {
        for (var idx = 0; idx < this.props.currentstore.pos_settings.length; idx++) {
            var df = this.props.currentstore.pos_settings[idx].deleted_flag;
            var sid = this.props.currentstore.pos_settings[idx].store_id;
            switch (this.props.currentstore.pos_settings[idx].pos_type_id) {
                case 1:
                    this.setState({ passportdeletedflag: df, passportstoreid: sid });
                    break;
                case 2:
                    this.setState({ commanderdeletedflag: df, commanderstoreid: sid });
                    break;
                case 5:
                    this.setState({ smartdesqdeletedflag: df, smartdesqstoreid: sid });
                    break;
                case 6:
                    var dailyFolders = this.props.currentstore.pos_settings[idx].daily_folders;
                    this.setState({ radiantdeletedflag: df, radiantstoreid: sid, radiantdailyfolders: dailyFolders });
                    break;
                default:
                    break;
            }
        }
    }

    handleSelect(e) {
        e.preventDefault();
        var id = parseInt(e.target.value, 10);
        this.setState({ index: id });
    }

    handleCancel(e) {
        e.preventDefault();
        this.setState({ index: 0 });
    }

    handleSave(e) {
        e.preventDefault();
        var values = this.form.getValues();
        this.props.parentCallback(this.state.index, values);
    }

    handleRadiantDailyFolders(e) {
        debugger;
        switch (e.target.value) {
            case "True":
                this.setState({ radiantdailyfolders: true });
                break;
            case "False":
                this.setState({ radiantdailyfolders: false });
                break;
            default:
                break;
        }
    }
    handleDeletedFlag(e) {
        var df = this.props.currentstore.pos_settings[this.state.index].deleted_flag;
        var sid = this.props.currentstore.pos_settings[this.state.index].store_id;
        switch (e.target.value) {
            case "Active":
                df = "N";
                sid = this.props.currentstore.store_id;
                break;
            case "Pause":
                df = "Y";
                break;
            case "AddToStore":
                df = "N";
                sid = this.props.currentstore.store_id;
                break;
            case "DoNotApply":
                df = "N";
                sid = 0;
                break;
            default:
                break;
        }
        switch (this.props.currentstore.pos_settings[this.state.index].pos_type_id) {
            case 1:
                this.setState({ passportdeletedflag: df, passportstoreid: sid });
                break;
            case 2:
                this.setState({ commanderdeletedflag: df, commanderstoreid: sid });
                break;
            case 5:
                this.setState({ smartdesqdeletedflag: df, smartdesqstoreid: sid });
                break;
            case 6:
                this.setState({ radiantdeletedflag: df, radiantstoreid: sid });
                break;

            default:
                break;
        }
    }

    renderCommander(idx) {
        var commandersettings = this.props.currentstore.pos_settings[idx].commander_settings;
        let deletedFlagbuttons = this.renderDeletedFlagButtons("commanderdeletedflag", idx);
        var tableclass = idx === this.state.index ? "posDisplayVisible" : "posDisplayHidden";
        return (
            <table className={tableclass } cellPadding="5">
                <thead>
                    <tr><th colSpan="3">POS ID#{this.props.currentstore.pos_settings[idx].pos_file_access_id} Commander</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Password interval (day)</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderPasswordInterval"
                                    value={commandersettings.reset_password_interval === null ? "" : commandersettings.reset_password_interval}
                                />
                            </div>
                        </td>
                        <td>Password Length</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderPasswordLength"
                                    value={commandersettings.password_length === null ? "8" : commandersettings.password_length}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Commander IP</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderIP"
                                    value={commandersettings.CommanderIP === null ? "" : commandersettings.CommanderIP}
                                />
                            </div>
                        </td>
                        <td>Retain Sent Files (days)</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderDaysToKeep"
                                    value={commandersettings.days_to_keep === null ? "90" : commandersettings.days_to_keep}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Commander Password</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderPassword"     
                                    value={commandersettings.CommanderPass === null ? "" : commandersettings.CommanderPass}
                                />
                            </div>
                        </td>
                        <td>Commander User</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderUsername"
                                    value={commandersettings.CommanderUser === null ? "" : commandersettings.CommanderUser}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">{ deletedFlagbuttons}</td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderDeletedFlag"
                                    style={{ display: "none" }}
                                    value={this.state.commanderdeletedflag}
                                />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="commanderStoreID"
                                    style={{ display: "none" }}
                                    value={this.state.commanderstoreid}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
                </table>
        );
    }
    renderPassport(idx) {

        var passportsettings = this.props.currentstore.pos_settings[idx].passport_settings;
        var tableclass = idx === this.state.index ? "posDisplayVisible" : "posDisplayHidden";
        let deletedFlagbuttons = this.renderDeletedFlagButtons("passportdeletedflag", idx);
        return (
            <table className={tableclass } cellPadding="5">
                    <thead>
                        <tr><th colSpan="3">POS ID#{this.props.currentstore.pos_settings[idx].pos_file_access_id} Passport</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Source Path</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportSourcePath"
                                        value={passportsettings.source_path === null ? "" : passportsettings.source_path}
                                        validations={[required] }
                                    />
                                </div>
                            </td>
                            <td>Retain Sent Files (days)</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportDaysToKeep"
                                        validations={[integeronly]}
                                    value={passportsettings.days_to_keep === null ? "" : passportsettings.days_to_keep}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan='2'>Accessing Passport With Elevated Access</th>
                            <th colSpan='2'>Accessing Passport From Passport FTP site</th>
                        </tr>
                        <tr>
                            <td>Passport Domain</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportDomain"
                                    value={passportsettings.passport_domain === null ? "" : passportsettings.passport_domain}
                                        />
                                </div>
                            </td>
                            <td>Ftp Connection Type</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportFTPConnectionType"
                                    value={passportsettings.ftp_connection_type === null ? "" : passportsettings.ftp_connection_type}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Passport Username</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportUsername"
                                    value={passportsettings.passport_username === null ? "" : passportsettings.passport_username}
                                    />
                                </div>
                            </td>
                            <td>Ftp Host</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportFTPHost"
                                    value={passportsettings.ftp_host === null ? "" : passportsettings.ftp_host}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Passport password</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportPassword"
                                    value={passportsettings.passport_password === null ? "" : passportsettings.passport_password}
                                    />
                                </div>
                            </td>
                            <td>Ftp User Name</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportFTPUsername"
                                    value={passportsettings.ftp_username === null ? "" : passportsettings.ftp_username}
                                    />
                                </div>
                            </td>
                        </tr>
                    <tr>
                        <td colSpan="2">
                            {deletedFlagbuttons }
                        </td>
                            <td>Ftp Password</td>
                            <td>
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="passportFTPPassword"
                                    value={passportsettings.ftp_password === null ? "" : passportsettings.ftp_password}
                                    />
                                </div>
                            </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="form-group">
                            <Input
                                type="text"
                                className="form-control"
                                name="passportDeletedFlag"
                                style={{ display: "none" }}
                                value={this.state.passportdeletedflag}
                            />
                            <Input
                                type="text"
                                className="form-control"
                                name="passportStoreID"
                                style={{ display: "none" }}
                                value={this.state.passportstoreid}
                                />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
        );
    }
    renderSmartdesq(idx) {
        var smartdesqsettings = this.props.currentstore.pos_settings[idx].smartdesq_settings;
        let deletedFlagbuttons = this.renderDeletedFlagButtons("smartdesqdeletedflag", idx);
        var tableclass = idx === this.state.index ? "posDisplayVisible" : "posDisplayHidden";
        return (
            <table className={tableclass } cellPadding="5">
                <thead>
                    <tr><th colSpan="3">POS ID#{this.props.currentstore.pos_settings[idx].pos_file_access_id} SmartDesq</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Invoke Path</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqExportInvokePath"
                                    value={smartdesqsettings.ExportInvokePath === null ? "" : smartdesqsettings.ExportInvokePath}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Source Path</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqSourceTransacionsLogPath"
                                    value={smartdesqsettings.SourceTransactionsLogPath === null ? "" : smartdesqsettings.SourceTransactionsLogPath}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Days Back Interval</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqDaysToGoBack"
                                    value={smartdesqsettings.DaysToGoBack === null ? "" : smartdesqsettings.DaysToGoBack}
                                />
                            </div>
                        </td>
                        <td>Retain Sent Files (days)</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqDaysToKeepSentFiles"
                                    value={smartdesqsettings.DaysToKeepSentFiles === null ? "90" : smartdesqsettings.DaysToKeepSentFiles}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Total Number of Requests</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqTotalNumberOfRequests"
                                    value={smartdesqsettings.TotalNumberOfRequests === null ? "60" : smartdesqsettings.TotalNumberOfRequests}
                                />
                            </div>
                        </td>
                        <td>Milliseconds Between Requests</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqMillisecondsToSleepBetweenRequests"
                                    value={smartdesqsettings.MillisecondsToSleepBetweenRequests === null ? "60000" : smartdesqsettings.MillisecondsToSleepBetweenRequests}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            {deletedFlagbuttons }
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqDeletedFlag"
                                    style={{ display: "none" }}
                                    value={this.state.smartdesqdeletedflag}
                                />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="smartdesqStoreID"
                                    style={{ display: "none" }}
                                    value={this.state.smartdesqstoreid}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    renderRadiant(idx) {
        var radiantsettings = this.props.currentstore.pos_settings[idx].radiant_settings;
        let deletedFlagbuttons = this.renderDeletedFlagButtons("radiantdeletedflag", idx);
        var tableclass = idx === this.state.index ? "posDisplayVisible" : "posDisplayHidden";
        return (          
            <table className = {tableclass} cellPadding = "5" >
                <thead>
                    <tr><th colSpan="3">POS ID#{this.props.currentstore.pos_settings[idx].pos_file_access_id} Radiant</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Source Path</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantSourcePath"
                                    value={radiantsettings.source_path === null ? "" : radiantsettings.source_path}
                                />
                            </div>
                        </td>
                        <td>Radiant Domain</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantElevatedDomain"
                                    value={radiantsettings.elevated_domain === null ? "" : radiantsettings.elevated_domain}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Radiant Username</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantUserName"
                                    value={radiantsettings.elevated_username === null ? "" : radiantsettings.elevated_username}
                                />
                            </div>
                        </td>
                        <td>Radiant Password</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantPassword"
                                    value={radiantsettings.elevated_password === null ? "" : radiantsettings.elevated_password}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Retain Sent Files (days)</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantDaysToKeep"
                                    value={radiantsettings.days_to_keep === null ? "" : radiantsettings.days_to_keep}
                                />
                            </div>
                        </td>
                        <td>Use Daily Subfolders</td>
                        <td colSpan="3">
                            <div className="form-group">
                                <input type="radio" name="radiantdailyfolders" value="True" defaultChecked={radiantsettings.daily_folders} onChange={this.handleRadiantDailyFolders} /> True (most common)
                                <span>&nbsp;&nbsp;</span>
                                <input type="radio" name="radiantdailyfolders" value="False" defaultChecked={radiantsettings.daily_folders === false} onChange={this.handleRadiantDailyFolders} /> False
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            {deletedFlagbuttons}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantDeletedFlag"
                                    style={{ display: "none" }}
                                    value={this.state.radiantdeletedflag}
                                />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantStoreID"
                                    style={{ display: "none" }}
                                    value={this.state.radiantstoreid}
                                />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="radiantDailyFolders"
                                    style={{ display: "none" }}
                                    value={this.state.radiantdailyfolders}
                                    />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table >
        );

    }
    renderDeletedFlagButtons(radioname, idx) {
        if (this.props.currentstore.pos_settings[idx].store_id === 0)
            return (<div>
                <input type="radio" name={radioname} value="AddToStore" onChange={this.handleDeletedFlag} /> Add To Store
                <span>&nbsp;&nbsp;</span>
                <input type="radio" name={radioname} value="DoNotApply" onChange={this.handleDeletedFlag} defaultChecked /> Do Not Apply
            </div>);
        if (this.props.currentstore.pos_settings[idx].deleted_flag === "Y")
            return (<div>
                <input type="radio" name={radioname} value="Active" onChange={this.handleDeletedFlag}  /> Active
                <span>&nbsp;&nbsp;</span>
                <input type="radio" name={radioname} value="Pause" onChange={this.handleDeletedFlag} defaultChecked /> Pause
            </div>);
        return (<div>
            <input type="radio" name={radioname} value="Active" onChange={this.handleDeletedFlag} defaultChecked /> Active
            <span>&nbsp;&nbsp;</span>
            <input type="radio" name={radioname} value="Pause" onChange={this.handleDeletedFlag} /> Pause
        </div>
        );

    }
    renderControlDisplay(idx) {
        let hs = this.handleSelect;
        return (
            <span className="posSpan">
                {this.props.currentstore.pos_settings.map(function (item, index) {
                    let classlist = index === idx ? "posTablink posTablinkSelected" : "posTablink";
                    let posStatus = item.store_id === 0 ? "Not Used" : item.deleted_flag === "Y" ? "Paused" : "Active";
                    return <button className={classlist} onClick={hs} value={index}>{posTypes[item.pos_type_id].posName} { posStatus}</button>
                }) }
            </span>
        );
    }
    render() {
        var allDisplays = [];
        for (var idx = 0; idx < this.props.currentstore.pos_settings.length; idx++) {
            let posDisplay = <h4>Unable to format POS at offset {idx}</h4>;
            switch (this.props.currentstore.pos_settings[idx].pos_type_id) {
                case 1:
                    posDisplay = this.renderPassport(idx);
                    break;
                case 2:
                    posDisplay = this.renderCommander(idx);
                    break;
                case 5:
                    posDisplay = this.renderSmartdesq(idx);
                    break;
                case 6:
                    posDisplay = this.renderRadiant(idx);
                    break;
                default:
                    posDisplay = <h4>Pos Type {this.props.currentstore.pos_settings[idx].pos_type_id} is not supported by this interface</h4>;
                    break;
            }
            allDisplays.push(posDisplay);
        }
        let controlData = this.renderControlDisplay(this.state.index);
        return (
            <div>
                <Form ref={c => { this.form = c; }}>
                    <div className="buttonsDiv">
                        <table cellPadding="5" >
                            <tbody>
                                <tr>
                                    <td>
                                        <button onClick={this.handleCancel}
                                            className="btn btn-primary btn-block btn-fixed-width"
                                            disabled={this.props.updateloading}>
                                            <span>Cancel</span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CheckButton onClick={this.handleSave}
                                            className="btn btn-primary btn-block btn-fixed-width"
                                            disabled={this.props.updateloading}>
                                            <span>Save</span>
                                        </CheckButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => { this.checkBtn = c; }}
                        />
                </div>
                <div className='updateDiv'>
                    {controlData}
                        {allDisplays}
                        {this.state.message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => { this.checkBtn = c; }}
                        />
                </div>
                </Form>
            </div>
        )

    }

}
export default StorePosTypes;