import { Home } from "./components/Home";
import StoreUpdate from "./components/StoreUpdate";
import Login from "./components/login";
import ParticipantUpdate from "./components/ParticipantUpdate";
import SystemStatus from './components/SystemStatus';

const AppRoutes = [
    {
        index: true,
        path: '/',
        element: <Home />
    },
    {
        path: 'Login',
        element: <Login />
    },
    {
        path: 'SystemStatus',
        element: <SystemStatus/>
    },
    {
        path: 'ParticipantUpdate',
        element: <ParticipantUpdate />
    },
    {
        path: 'StoreUpdate',
        element: <StoreUpdate />
    }
];

export default AppRoutes;
