import React, { Component } from 'react';
import AuthService from "../services/auth";
import authHeader from '../services/auth-header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//https://www.npmjs.com/package/react-validation/v/1.4.1
import Form from 'react-validation/build/form'
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";

//https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';     //Used to add a paginator to the BootstrapperTable
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import StoreLogs from './StoreLogs.js';

const integeronly = value => {
    if (value && isNaN(value)) return (<div className="alert alert-danger" role="alert">
        This field must be numeric!
    </div>
    );
}
const expandRow = {
    renderer: row => (
        <div>
            <p>{row.message}</p>
        </div>
    )
};

const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.level === "ERROR")
    {
        style.backgroundColor = 'salmon';
    }


    return style;
};
export class SystemStatus extends Component {
    static displayName = SystemStatus.name;
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleMore = this.handleMore.bind(this);

        this.onChangeSearchAccountID = this.onChangeSearchAccountID.bind(this);
        this.onChangeSearchAccountName = this.onChangeSearchAccountName.bind(this);
        this.onChangeSearchCity = this.onChangeSearchCity.bind(this);
        this.onChangeSearchID = this.onChangeSearchID.bind(this);
        this.onChangeSearchState = this.onChangeSearchState.bind(this);
        this.onChangeSearchStoreName = this.onChangeSearchStoreName.bind(this);
        this.onChangeSearchZip = this.onChangeSearchZip.bind(this);

        this.state =
        {
            userReady: false,
            currentUser: { username: "" },
            loading: false,
            searchStoreID: "",
            searchStoreName: "",
            searchAccountID: "",
            searchAccountName: "",
            searchCity: "",
            searchState: "",
            searchZip: "",
            columns: [
                { dataField: 'log4net_id', sort: true, text: 'Log id', hidden: true },
                { dataField: 'store_id', sort: true, text: 'Store ID', headerClasses: 'td-10' },
                {
                    dataField: 'logdateutc', sort: true, text: 'Log Date (local time)', formatter: (cellContent, row) => {
                        var dt = new Date(row.logdateutc);
                        var min = dt.getTimezoneOffset();
                        var ndt = new Date(dt.getTime() - min * 60000);
                        return (<span>{ndt.toLocaleDateString()} {ndt.toLocaleTimeString()}</span>)
                    }
                },
                { dataField: 'level', sort: true, text: 'Level', filter: textFilter({ defaultValue: 'ERROR' }) },
                { dataField: 'store_name', sort: true, text: 'Store Name' },
                {
                    dataField: 'show_details', text: "", isDummyField: true, events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => { this.handleMore(e, row.store_id, row.store_name); }
                    }, formatter: (cellContent, row) => { return (<button> More </button>); }
                }
            ],
            stores: null,
            selected: null,
            currentstore: null,
            currentParticipant: null,
            currenttab: 0,
            originalstore: null,
            contactErrors: null,
            updateloading: false,
            lastupdatestatus: null,
            expandedLogs: null

        };
    }
    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser || currentUser.loggedin === false) {
            AuthService.logout();
            this.setState({ currentUser: null, userReady: false });
            alert("SystemStatus - componentDidMount\r\nLogin expired.  Navigate to home page");
        }
        else {
            this.setState({ currentUser: currentUser, userReady: true })
        }
    }

    onChangeSearchID(e) { this.setState({ searchStoreID: e.target.value }); }
    onChangeSearchStoreName(e) { this.setState({ searchStoreName: e.target.value }); }
    onChangeSearchAccountID(e) { this.setState({ searchAccountID: e.target.value }); }
    onChangeSearchAccountName(e) { this.setState({ searchAccountName: e.target.value }); }
    onChangeSearchCity(e) { this.setState({ searchCity: e.target.value }); }
    onChangeSearchState(e) { this.setState({ searchState: e.target.value }); }
    onChangeSearchZip(e) { this.setState({ searchZip: e.target.value }); }

    handleMore(e, store_id, name) {
        let msg = <StoreLogs store_id={store_id} storename={name} />;
        confirmAlert({
            message: msg,
            buttons: [
                {
                    label: 'Go Back'
                }
            ]
        });

    }
    handleMorex(e, store_id) {
        var edt = new Date();
        edt.setDate(edt.getDate() + 1);
        var sdt = new Date();
        sdt.setMonth(sdt.getMonth() - 1);
        let requestOptions =
        {
            method: 'post',
            headers: authHeader(),
            body: JSON.stringify({
                store_id: store_id,
                startDate: sdt.toISOString(),
                endDate: edt.toISOString()
            })
        };
        console.log(requestOptions);
        fetch('/TAClient/GetLogEntries', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    this.setState({ message: response.statusText });
                    if (response.status === 401) {
                        AuthService.logout();
                        alert("Login expired");
                    }
                    else throw new Error(response.status);
                }
                else return response.json();
            })
            .then((dobj) => {
                this.setState({ loading: false });
                if (dobj.status) {
                    console.log(dobj);
                    this.setState({ expandedLogs: dobj });
                }
                else {
                    this.setState({
                        expandedLogs: null
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    expandedLogs: null,
                    loading: false
                });
            });
    }
    //*****  User wants to clear search criteria */
    handleClear(e) {
        e.preventDefault();
        this.setState({
            searchAccountID: "", searchAccountName: "", searchCity: "", searchState: "", searchStoreID: "", searchStoreName: "", searchZip: "",
            currentstore: null
        });
    }

    //*****  User has entered criteria for the search */
    handleSearch(e) {
        e.preventDefault();
        this.setState({
            message: "",
            currentstore: null,
            currenttab: 0,
            loading: true
        });

        this.form.validateAll();

        let requestOptions =
        {
            method: 'post',
            headers: authHeader(),
            body: JSON.stringify({
                store_id: this.state.searchStoreID === "" ? null : this.state.searchStoreID,
                store_name: this.state.searchStoreName === "" ? null : this.state.searchStoreName,
                account_id: this.state.searchAccountID === "" ? null : this.state.searchAccountID,
                account_name: this.state.searchAccountName === "" ? null : this.state.searchAccountName,
                city: this.state.searchCity === "" ? null : this.state.searchCity,
                state: this.state.searchState === "" ? null : this.state.searchState,
                zip: this.state.searchZip === "" ? null : this.state.searchZip
            })
        };
        fetch('/TAClient/SystemStatus', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    this.setState({ message: response.statusText });
                    if (response.status === 401) {
                        AuthService.logout();
                        alert("Login expired");
                    }
                    else throw new Error(response.status);
                }
                else return response.json();
            })
            .then((dobj) => {
                this.setState({ loading: false });
                if (dobj.status) {
                    this.setState({ stores: dobj.stores });
                }
                else {
                    this.setState({
                        message: dobj.status_text,
                        stores: null
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    stores: null,
                    loading: false
                });
            });


    }


    render() {
        let storeGridData = this.state.stores === null
            ? ""
            : <div>
                <BootstrapTable columns={this.state.columns} data={this.state.stores} keyField='log4net_id' bootstrap4={true}
                    expandRow={expandRow} rowStyle={rowStyle} headerClasses='tr-sticky' pagination={paginationFactory()} filter={filterFactory()} />
            </div> 


        return (
            <div>
                <h1 id="tableLabel">System Status</h1>
                <div>
                    <div className="searchparams">
                        <Form onSubmit={this.handleSearch}
                            ref={c => {
                                this.form = c;
                            }}>
                            <table cellPadding="5" width="100%">
                                <tbody>
                                    <tr>
                                        <td valign="top">Store ID:</td>
                                        <td valign="top">
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchStoreID"
                                                    value={this.state.searchStoreID}
                                                    onChange={this.onChangeSearchID}
                                                    validations={[integeronly]}
                                                />
                                            </div>
                                        </td>
                                        <td valign="top">Store Name:</td>
                                        <td valign="top">
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchStoreName"
                                                    value={this.state.searchStoreName}
                                                    onChange={this.onChangeSearchStoreName}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Account ID:</td>
                                        <td>
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchAccountID"
                                                    value={this.state.searchAccountID}
                                                    onChange={this.onChangeSearchAccountID}
                                                    validations={[integeronly]}
                                                />
                                            </div>
                                        </td>
                                        <td valign="top">Account Name:</td>
                                        <td>
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchAccountName"
                                                    value={this.state.searchAccountName}
                                                    onChange={this.onChangeSearchAccountName}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">City:</td>
                                        <td>
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchCity"
                                                    value={this.state.searchCity}
                                                    onChange={this.onChangeSearchCity}
                                                />
                                            </div>
                                        </td>
                                        <td valign="top">State:</td>
                                        <td>
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchState"
                                                    value={this.state.searchState}
                                                    onChange={this.onChangeSearchState}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Zip Code</td>
                                        <td>
                                            <div className="form-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="searchZip"
                                                    value={this.state.searchZip}
                                                    onChange={this.onChangeSearchZip}
                                                    validations={[integeronly]}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" align="center">
                                            <div className="form-group">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    disabled={this.state.loading}
                                                    onClick={this.handleClear}>
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    <span>Clear Criteria</span>
                                                </button>
                                            </div>
                                        </td>
                                        <td colSpan="2" align="center">
                                            <div className="form-group">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    disabled={this.state.loading}>
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                    <span>Find Stores</span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>


                            {this.state.message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                            <CheckButton
                                style={{ display: "none" }}
                                ref={c => { this.checkBtn = c; }}
                            />
                        </Form>
                    </div>
                    <div className="storeinfo">
                        {storeGridData}
                    </div>

                </div>
            </div>);
    }
}
export default SystemStatus;