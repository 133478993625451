import React, { Component } from 'react';

import AuthService from "../services/auth";
import './StoreUpdate.css';

import Form from 'react-validation/build/form'
import Input from "react-validation/build/input";
import validator from 'validator';
import CheckButton from "react-validation/build/button";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const email = (value) => {
    if (!validator.isEmail(value)) {
        return (<div className="alert alert-danger" role="alert">${value} is not a valid email</div>)
    }
};

const phone = (value) => {
    if (value !== null && value.length > 0 && !validator.isMobilePhone(value)) {
        return (<div className="alert alert-danger" role="alert">${value} is not a valid phone number</div>)
    }
}
export class StoreContacts extends Component {

    static displayName = StoreContacts.name;


    constructor(props) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        var primary = this.props.currentstore.primary;
        var owner = this.props.currentstore.owner;
        this.state = {
            userReady: true,
            currentstore: this.props.currentstore,
            primaryTitle: primary == null ? "" : primary.title == null ? "" : primary.title,
            primaryFirstName: primary == null ? "" : primary.first_name == null ? "" : primary.first_name,
            primaryLastName: primary == null ? "" : primary.last_name == null ? "" : primary.last_name,
            primaryPhonetext: primary == null ? "" : primary.phonetext == null ? "" : primary.phonetext,
            primaryEmail: primary == null ? "" : primary.email == null ? "" : primary.email,
            ownerTitle: owner == null ? "" : owner.title == null ? "" : owner.title,
            ownerFirstName: owner == null ? "" : owner.first_name == null ? "" : owner.first_name,
            ownerLastName: owner == null ? "" : owner.last_name == null ? "" : owner.last_name,
            ownerPhonetext: owner == null ? "" : owner.phonetext == null ? "" :  owner.phonetext,
            ownerEmail: owner == null ? "" : owner.email == null ? "" : owner.email

        }
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser || currentUser.loggedin === false) {
            alert("Detected Logged off in StoreContacts");
        }
        else {
            this.setState({ "currentuser": currentUser });
        }
    }

    handleFieldChange(e) {
        switch (e.target.name) {
            case "primaryTitle": this.setState({ "primaryTitle": e.target.value }); break; 
            case "primaryFirstName": { this.setState({ "primaryFirstName": e.target.value }); break; }
            case "primaryLastName": { this.setState({ "primaryLastName": e.target.value }); break; }
            case "primaryPhonetext": { this.setState({ "primaryPhonetext": e.target.value }); break; }
            case "primaryEmail": { this.setState({ "primaryEmail": e.target.value }); break; }
            case "ownerTitle": { this.setState({ "ownerTitle": e.target.value }); break; }
            case "ownerFirstName": { this.setState({ "ownerFirstName": e.target.value }); break; }
            case "ownerLastName": { this.setState({ "ownerLastName": e.target.value }); break; }
            case "ownerPhonetext": { this.setState({ "ownerPhonetext": e.target.value }); break; }
            case "ownerEmail": { this.setState({ "ownerEmail": e.target.value }); break; }
            default:
                console.log("StoreContact.js - field name " + e.target.name + " not listed in handleFieldChange");
                break;
        }
    }
    //values.primaryPhonetext == null ? "" : values.primaryPhonetext;
    handleCancel(e) {
        e.preventDefault();
        var primary = this.props.currentstore.primary;
        var owner = this.props.currentstore.owner;
        this.setState({
            currentstore: this.props.currentstore,
            primaryTitle: primary.title == null ? "" :primary.title,
            primaryFirstName: primary.first_name == null ? "" : primary.first_name,
            primaryLastName: primary.last_name == null ? "" : primary.last_name,
            primaryPhonetext: primary.phonetext == null ? "" : primary.phonetext,
            primaryEmail: primary.email == null ? "" : primary.email,
            ownerTitle: owner.title == null ? "" : owner.title,
            ownerFirstName: owner.first_name == null ? "" : owner.first_name,
            ownerLastName: owner.last_name == null ? "" : owner.last_name,
            ownerPhonetext: owner.phonetext == null ? "" : owner.phonetext,
            ownerEmail: owner.title == null ? "" : owner.email
        });
    }

    handleSave(e) {
        e.preventDefault();
        this.form.validateAll();
        this.setState({ message: "" });
        var messageText = "";
        var values = this.form.getValues();

        var ptext = values.primaryPhonetext == null ? "" : values.primaryPhonetext;
        var otext = values.ownerPhonetext == null ? "" : values.ownerPhonetext;
        if ((ptext.trim().length === 0 || ptext.trim() === "0") && (otext.trim().length === 0 || otext.trim() === "0")) {
            messageText += "You must specify at least one phone number";
        }

        if (messageText.length > 0) {

            confirmAlert({
                title: 'Form incomplete',
                message: messageText,
                buttons: [
                    {
                        label: 'Continue',
                    }
                ]
            });
        }
        else this.props.parentCallback(values);
    }

    render() {
        return (
            <div>
                <Form ref={c => { this.form = c; }}>
                    <div className="buttonsDiv">
                        <table cellPadding="5" >
                            <tbody>
                                <tr>
                                    <td>
                                        <button onClick={this.handleCancel}
                                            className="btn btn-primary btn-block btn-fixed-width"
                                            disabled={this.props.updateloading}>
                                            <span>Cancel</span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CheckButton onClick={this.handleSave}
                                            className="btn btn-primary btn-block btn-fixed-width"
                                            disabled={this.props.updateloading}>
                                            <span>Save</span>
                                        </CheckButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <CheckButton
                            style={{ display: "none" }}
                            ref={c => { this.checkBtn = c; }}
                        />
                </div>
                <div className="updateDiv" onBlur={this.handleBlur}>
                        <table cellPadding="5" >
                            <thead>
                                <tr><th></th><th colSpan="2">Primary Contact At Store</th><th colSpan="2">Owner</th></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Title</td>
                                    <td valign="top" colSpan="2">
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="primaryTitle"
                                                tabIndex="1"
                                                value={this.state.primaryTitle}
                                                onChange={this.handleFieldChange }
                                            />
                                        </div>
                                    </td>
                                    <td valign="top" colSpan="2">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="ownerTitle"
                                            tabIndex="11"
                                            value={this.state.ownerTitle}
                                            onChange={this.handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">Name</td>
                                    <td valign="top">
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="primaryFirstName"
                                                tabIndex="2"
                                                value={this.state.primaryFirstName}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="primaryLastName"
                                                tabIndex="3"
                                                value={this.state.primaryLastName}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="ownerFirstName"
                                                tabIndex="12"
                                                value={this.state.ownerFirstName}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="ownerLastName"
                                                tabIndex="13"
                                                value={this.state.ownerLastName}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td colSpan="2">
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="primaryPhonetext"
                                                tabIndex="4"
                                                validations={[phone]}
                                                value={this.state.primaryPhonetext}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                    <td colSpan="2">
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="ownerPhonetext"
                                                tabIndex="14"
                                                validations={[phone]}
                                                value={this.state.ownerPhonetext}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td colSpan="2">
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="primaryEmail"
                                                tabIndex="5"
                                                validations={[email]}
                                                value={this.state.primaryEmail}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                    <td colSpan="2">
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="ownerEmail"
                                                tabIndex="15"
                                                validations={[email]}
                                                value={this.state.ownerEmail}
                                                onChange={this.handleFieldChange}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </div>
                </Form>
            </div>
        )
    }
}
export default StoreContacts;
