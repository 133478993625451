import React, { Component } from 'react';
import AuthService from "../services/auth";
import authHeader from '../services/auth-header';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';     //Used to add a paginator to the BootstrapperTable
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './StoreUpdate.css';

const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.level === "ERROR") {
        style.backgroundColor = 'salmon';
    }


    return style;
};
const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
const toMMddYY = date => {
    return pad(date.getMonth() + 1) +
        "/" + pad(date.getDate()) +
        "/" + pad(date.getFullYear() % 100) +
        " " + pad(date.getHours()) +
        ":" + pad(date.getMinutes()) +
        ":" + pad(date.getSeconds());
};
export class StoreLogs extends Component {
    static displayName = StoreLogs.name;
    constructor(props) {
        super(props);
        var sdt = new Date();
        sdt.setMonth(sdt.getMonth() - 1);
        var edt = new Date();
        edt.setDate(edt.getDate() + 1);
        this.state =
        {
            currentUser: null,
            loading: true,
            messages: null,
            startDate: sdt,
            endDate: edt,
            showDateRange: true,
            columns:
                [
                { dataField: 'log4net_id', sort: true, text: 'Log id', hidden: true },
                { dataField: 'insert_date', sort: true, text: 'Date (local time)', 
                        formatter: (cellContent, row) => {
                            var dt = new Date(row.insert_date);
                            var min = dt.getTimezoneOffset();
                            var ndt = new Date(dt.getTime() - min * 60000);
                            var fmtTime = toMMddYY(ndt);
                            return (<span>{fmtTime}</span>)
                        },
                        headerStyle: (colum, colIndex) => { return { width: '160px' }; }
                    },
                    {
                        dataField: 'participant_name', sort: true, text: 'Participant', filter: textFilter(), headerStyle: (column, colIndex) => { return { width: '80px' }; }
                    },
                    {
                        dataField: 'level', sort: true, text: 'Level', filter: textFilter({ defaultValue: this.props.levelFilter }), headerStyle: (column, colIndex) => { return { width: '140px' }; }
                    },
                    { dataField: 'message', text: 'Message', filter: textFilter(), headerStyle: (column, colIndex) => { return { width: '800px' }; } }    
            ],
            defaultSorted: [{
                dataField: 'insert_date',
                order: 'desc'
            }]

        }
        this.getLogs = this.getLogs.bind(this);
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
        this.handleLogSearch = this.handleLogSearch.bind(this);
    }
    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser || currentUser.loggedin === false) {
            AuthService.logout();
            this.setState({ currentUser: null, userReady: false });
            alert("StoreLogs - componentDidMount\r\nLogin expired.  Navigate to home page");
        }
        else {
            this.setState({ currentUser: currentUser, userReady: true })
            this.getLogs();
        }
    }

    handleLogSearch(e) {
        this.getLogs();
    }
    getLogs()
    {
        this.setState({"showDateRange": true})
            let requestOptions =
            {
                method: 'post',
                headers: authHeader(),
                body: JSON.stringify({
                    store_id: this.props.store_id,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    limit: 1000
                })
            };
            console.log(requestOptions);
            fetch('/TAClient/GetLogEntries', requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        this.setState({ message: response.statusText });
                        if (response.status === 401) {
                            AuthService.logout();
                            alert("Login expired");
                        }
                        else throw new Error(response.status);
                    }
                    else return response.json();
                })
                .then((dobj) => {
                    this.setState({ loading: false });
                    if (dobj.status) {
                        console.log(dobj);
                        this.setState({ messages: dobj.messages });
                    }
                    else {
                        this.setState({
                            messages: null
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        messages: null,
                        loading: false
                    });
                });
        }
    

    handleDateTimeChange(date, dateIndex) {
        console.log("handleDateTime = " + date.toLocaleDateString());
        if (dateIndex === 1) { this.setState({ "showDateRange": false, "startDate": date }); }
        else {this.setState({"showDateRange": false, "endDate": date }); }
    }

    render() {
        let dateRangeText = this.state.showDateRange
            ? <p>No Log Entries Found between {this.state.startDate.toLocaleDateString()} and {this.state.endDate.toLocaleDateString()}</p>
            : <p></p>
        let captionElement = <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.props.storename}</h3>;

        let dsp = (this.state.messages == null)
            ? <p>Loading...</p>
            : this.state.messages.length === 0 ?
                <div>
                    {dateRangeText}
                    <table>
                    <tbody>
                        <tr>
                            <td>
                                <DatePicker selected={this.state.startDate} showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    onChange={(date) => this.handleDateTimeChange(date, 1)}
                                    dateFormat="MM/dd/yyyy h:mm aa"></DatePicker>
                            </td>
                            <td> - </td>
                            <td><DatePicker selected={this.state.endDate} showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                onChange={(date) => this.handleDateTimeChange(date, 2)}
                                dateFormat="MM/dd/yyyy h:mm aa"></DatePicker></td>
                            <td><button onClick={this.handleLogSearch} className="btn btn-primary btn-block">Get Logs</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                : <div style={{maxWidth : "1400px"} }>
                <BootstrapTable columns={this.state.columns} data={this.state.messages} keyField='log4net_id' bootstrap4={true}
                        defaultSorted={this.state.defaultSorted} caption={captionElement} classes="small-table"
                    rowStyle={rowStyle} headerClasses='tr-sticky' pagination={paginationFactory()} filter={filterFactory()} />
            </div> 
        return (dsp);
    }
}
export default StoreLogs;