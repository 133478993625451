import React, { Component } from 'react';
import AuthService from "../services/auth";
import Login from './login.js';


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state =
        {
            redirect: null,
            userReady: false,
            currentUser: { username: "", loggedin: false }
        };
    }

    render() {
        const currentUser = AuthService.getCurrentUser();
        let homepage = <h2>Home</h2>
        let contents = (currentUser == null || currentUser.username === "" || currentUser.loggedin === false) ? <Login /> : homepage;
        return (
            <div>
                <div>
                    {contents}
                </div>
            </div>
        );

    }
}
