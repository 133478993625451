class AuthService {
    login(pcallerid, pusername, ppassword) {

        let requestOptions =
        {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( { callerId: pcallerid, userName: pusername, password: ppassword, email: "" })
        };

        const res = fetch('/Login', requestOptions)
            .then((response) => {
                if (!response.ok) throw new Error(response.statusText);
                else
                {
                    return response.text();
                }
            })
            .then((data) => {
                var dt = new Date();
                localStorage.setItem("user", JSON.stringify({ JWT: data, callerid: pcallerid, username: pusername, logindate: dt, loginstatus: "Success", loggedin: true }));
                return "Success";
            })
            .catch((error) => {
                localStorage.setItem("user", JSON.stringify({ JWT: "", callerid: pcallerid, username: pusername, logindate: null, loginstatus: error, loggedin: false }));
                return error;
            });
        return res;
    }

    logout() {
        localStorage.removeItem("user");
    }


    getCurrentUser() {
        var currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser != null) {
            var dt = new Date(currentUser.logindate);
            var n = new Date();
            var gap = n - dt;
            if (gap > 3600000) {
                localStorage.removeItem("user");
                currentUser = null;
            }

        }
        return currentUser;
    }
}

export default new AuthService();