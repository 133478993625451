import React, { Component } from 'react';
import Form from 'react-validation/build/form'
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

var exportTypes = [
    { exportTypeId: 0, addType: 0, exportName: "Unknown" },
    { exportTypeId: 1, addType: 101, exportName: "API" },
    { exportTypeId: 2, addType: 102, exportName: "FTP" },
    { exportTypeId: 3, addType: 103, exportName: "SFTP" }
];
var FTPConnectionTypes = [
    { ftp_connection_type_id: 1, Description: "AutoActive"},
    { ftp_connection_type_id: 2, Description: "AutoPassive" },
    { ftp_connection_type_id: 3, Description: "PASV" },
    { ftp_connection_type_id: 4, Description: "EPSV" },
    { ftp_connection_type_id: 5, Description: "PORT" },
    { ftp_connection_type_id: 6, Description: "EPRT" },
    { ftp_connection_type_id: 7, Description: "PASVEX" },
    { ftp_connection_type_id: 8, Description: "PASVUSE" }
];

const filenameRegex = /{(.*?)}/g;

const filenameformattester = (value) => {
    if (value.toString().trim().length > 0) {
        var retValue = null;
        const farray = [...value.matchAll(filenameRegex)];
        farray.forEach(function (item, index) {
            var segs = item[1].split(":");
            switch (segs[0]) {
                case "pos_type":
                    break;
                case "store_id":
                    break;
                case "rundate":
                    break;
                case "starttrans":
                    break;
                case "endtrans":
                    break;
                default:
                    retValue = (<div className="alert alert-danger" role="alert">
                        The token {item[0]} is not valid!
                    </div>);
                    break;
            }
        });
        return retValue;
    }
};
const required = (value) => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return (<div className="alert alert-danger" role="alert">
            This field is required!
        </div>);
    }
};

const integeronly = value => {
    if (value && isNaN(value)) return (<div className="alert alert-danger" role="alert">
        This field must be numeric!
    </div>
    );
}

export class ParticipantExports extends Component {
    static displayName = ParticipantExports.name;


    constructor(props) {
        console.log("ParticpantExports.js");
        super(props);
        console.log(props);
        this.handlePickExport = this.handlePickExport.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleTrueFalse = this.handleTrueFalse.bind(this);
        this.handleFtpConnectionTypeID = this.handleFtpConnectionTypeID.bind(this);
        this.checkForChanges = this.checkForChanges.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderExport = this.renderExport.bind(this);

        this.state =
        {
            redirect: null,
            message: null,
            index: -1,
            lastupdatestatus: null,
            currentUser: props.currentUser,
            currentParticipant: props.currentParticipant,
            heading: "",
            enabled_flag: false,
            initial_install_flag: false,
            receive_log_flag: false,
            receive_summary_flag: false,
            ftp_connection_type_id: 0
        }
    }
    
    checkForChanges(e) {
        e.preventDefault();
        var idx = this.state.index;
        var el = this.props.currentParticipant.exportList[idx];
        var changed = false;
        this.setState({ lastupdatestatus: "" });
        if (typeof this.checkBtn !== 'undefined' && this.checkBtn !== null) {
            try {
                var values = this.form.getValues();
                var ftp_port = 0;
                if (typeof values["ftp_port"] === "number") ftp_port = values["ftp_port"];
                if (typeof values["ftp_port"] === "string" && values["ftp_port"] !== "") ftp_port = parseInt(values["ftp_port"], 10);
                var ftp_connection_type_id = 0;
                if (typeof values["ftp_connection_type_id"] === "number") ftp_connection_type_id = values["ftp_connection_type_id"];
                if (typeof values["ftp_connection_type_id"] === "string" && values["ftp_connection_type_id"] !== "") ftp_connection_type_id = parseInt(values["ftp_connection_type_id"], 10);

                changed = false;
                if (values["subfolder"] !== el.subfolder) changed = true;
                if (values["domain"] !== el.domain) changed = true;
                if (values["username"] !== el.username) changed = true;
                if (values["password"] !== el.password) changed = true;
                if (values["ftp_host"] !== el.ftp_host) changed = true;
                if (ftp_port !== el.ftp_port) changed = true;
                if (values["receive_summary_flag"] !== el.receive_summary_flag.toString()) changed = true;
                if (values["receive_log_flag"] !== el.receive_log_flag.toString()) changed = true;
                if (values["enabled_flag"] !== el.enabled_flag.toString()) changed = true;
                if (values["initial_install_flag"] !== el.initial_install_flag.toString()) changed = true;
                if (values["filename_format"] !== el.filename_format) changed = true;
                if (ftp_connection_type_id !== el.ftp_connection_type_id) changed = true;
                if (changed) {
                    confirmAlert({
                        title: 'Pending changes',
                        message: 'If you continue, You will lose the changes you have already made.  Do you want to continue anyway?',
                        buttons: [
                            {
                                label: 'Yes',
                                onClick: () => this.handlePickExport(e)
                            },
                            {
                                label: 'No',
                            }
                        ]
                    });
                }
                else {
                    this.handlePickExport(e);
                }
            }
            catch (e) {
                console.log("error in checkForChanges: " + e);
            }
        }
        else {
            this.handlePickExport(e);
        }

    }

    handlePickExport(e) {
        var idx = parseInt(e.target.value, 10);
        var el = this.props.currentParticipant.exportList[idx];
        this.setState({ lastupdatestatus: "" });
        if (idx > 100) {
            var newObject = {
                export_method_id: 0, export_type_id: idx - 100, participant_id: this.props.currentUser.callerid,
                    filename_format: "{pos_type}_{store_id}_{rundate:yyyyMMdd}_{starttrans:yyMMDD_HH_mm}_{endtrans:yyMMDD_HH_mm}",
                    domain: "", ftp_host: "", ftp_port: 0, ftp_connection_type_id: 0, 
                    username: "", password: "", receive_summary_flag: true, receive_log_flag: true, initial_install_flag: false,
                    enabled_flag: true,deleted_flag: "N"
            };
            switch (idx) {
                case 101:
                    newObject["subfolder"] = "API";
                    newObject["ftp_port"] = 0;
                    newObject["ftp_connection_type_id"] = 0;
                    break;
                case 102:
                    newObject["subfolder"] = "FTP";
                    newObject["ftp_port"] = 21;
                    newObject["ftp_connection_type_id"] = 1;
                    break;
                case 103:
                    newObject["subfolder"] = "SFTP";
                    newObject["ftp_port"] = 2222;
                    newObject["ftp_connection_type_id"] = 1;
                    break;

                default:
                    break;
            }
            this.props.currentParticipant.exportList.push(newObject);
            idx = this.props.currentParticipant.exportList.length - 1;

        }
        el = this.props.currentParticipant.exportList[idx];
        var headingText = el.export_method_id === 0 ? "Adding new " + exportTypes[el.export_type_id].exportName + " export"
            : "Updating settings for " + exportTypes[el.export_type_id].exportName + " Export Method #" + el.export_method_id;

        this.setState({
            index: idx, 
            heading: headingText,
            enabled_flag: el.enabled_flag,
            initial_install_flag: el.initial_install_flag,
            receive_log_flag : el.receive_log_flag,
            receive_summary_flag: el.receive_summary_flag,
            FTPConnectionTypes: el.ftp_connection_type_id,
            message: ""
        });
    }

    handleSave(e) {
        e.preventDefault();
        var values = this.form.getValues();
        this.props.parentCallback(this.state.index, values);
        this.setState({ lastupdatestatus: this.props.lastupdatestatus });
    }

    handleCancel(e) {
        e.preventDefault();
        var idx = this.state.index;
        var el = this.props.currentParticipant.exportList[idx];
        if (el.export_method_id === 0) {
            this.props.currentParticipant.exportList.splice(idx, 1);
        }
        this.setState({
            index: -1,
            heading: "",
            enabled_flag: false,
            initial_install_flag: false,
            receive_log_flag: false,
            receive_summary_flag: false,
            ftp_connection_type_id: el.ftp_connection_type_id
        });
    }

    handleFtpConnectionTypeID(e) {
        e.preventDefault();
        this.setState({ "ftp_connection_type_id": e.target.value });
    }

    handleTrueFalse(e) {
        switch (e.target.name) {
            case "enabled_flagtemp":
                this.setState({ enabled_flag: e.target.value === "true" ? true : false });
                break;
            case "initial_install_flagtemp":
                this.setState({ initial_install_flag: e.target.value === "true" ? true : false });
                break;
            case "receive_summary_flagtemp":
                this.setState({ receive_summary_flag: e.target.value === "true" ? true : false });
                break;
            case "receive_log_flagtemp":
                this.setState({ receive_log_flag: e.target.value === "true" ? true : false });
                break;
            default:
                break;
        }
    }

    renderDisplay() {
        var idx = this.state.index;
        var etype = -1;
        if (idx < 0) return <p></p>;
        if (idx < 100) etype = this.props.currentParticipant.exportList[idx].export_type_id;
        else etype = idx - 100;
        var el = this.props.currentParticipant.exportList[idx];
        let dspp = <h3>{etype}</h3>;
        if (etype >= 1 && etype <= 3) dspp = this.renderExport(el, etype);

        return dspp;
    }

    renderButtons() {
        let hb = this.checkForChanges;
        return (
            <div id="buttonsDiv" style={{ float: "left" }}>
                <table cellPadding="5">
                    <tbody>
                        {this.props.currentParticipant.exportList.map(function (item, index) {
                            let enabledStatus = item.export_method_id === 0 ? "New" : item.enabled_flag === true ? "Enabled" : "Disabled";
                            return <tr><td><button onClick={hb} className="btn btn-primary btn-block btn-fixed-width" value={index}>{exportTypes[item.export_type_id].exportName} {enabledStatus}</button></td></tr>
                        })}
                        <tr>
                            <td><hr /></td>
                        </tr>

                        {exportTypes.map(function (item, index) {
                            return index === 0 ? "" :
                                <tr><td><button onClick={hb} className="btn btn-primary btn-block btn-fixed-width"  value={item.addType}>Add {item.exportName}</button></td></tr>
                        })}
                        <tr>
                            <td>
                                {this.state.message}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        );
    }

    renderExport(el, etype) {
        var idx = this.state.index;
        var etypeDisplay = etype === 1 ? "collapse" : "visible";
        return <Form ref={c => { this.form = c; }}>
            {this.state.message && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {this.state.message}
                    </div>
                </div>
            )}
            <table cellPadding="5">
                <thead>
                    <tr><th colSpan="3">{this.state.heading}</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Client Subfolder</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    ref={c => { this.subfolder = c; }}
                                    className="form-control"
                                    name="subfolder"
                                    value={this.props.currentParticipant.exportList[idx].subfolder}
                                    validations={[required]}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr style={{visibility: etypeDisplay} }>
                        <td>FTP Host</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="ftp_host"
                                    value={el.ftp_host}
                                    validations={etype === 1 ? [] : [required]}
                                />
                            </div>
                        </td>
                        <td>Port</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="ftp_port"
                                    value={el.ftp_port}
                                    validations={etype === 1 ? [] : [required, integeronly]}
                                />
                            </div>
                        </td>
                        <td>Connection Type</td>
                        <td rowSpan="3" valign="top">
                            <div className="form-group" >
                                <select name="ftp_connection_type_id" className="form-control" defaultValue={this.state.ftp_connection_type_id} onChange={this.handleFtpConnectionTypeID }>
                                    {FTPConnectionTypes.map(function (item, index) {
                                        return <option value={item.ftp_connection_type_id}>{item.Description}</option>
                                    })}
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ visibility: etypeDisplay }}>
                        <td>FTP Username</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="username"
                                    value={el.username}
                                    validations={etype === 1 ? [] : [required]}
                                />
                            </div>
                        </td>
                        <td>Password</td>
                        <td>
                            <div className="form-group">
                                <Input
                                    type="password"
                                    placeholder="FTP Password"
                                    className="form-control"
                                    name="password"
                                    value={el.password}
                                    validations={etype === 1 ? [] : [required]}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Filename Format</td>
                        <td colSpan="5">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="filename_format"
                                    value={el.filename_format}
                                    validations={[required, filenameformattester]}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            Make this the default for new installations
                        </td>
                        <td colSpan="2">
                            <input type="radio" name="initial_install_flagtemp" value="true" defaultChecked={this.state.initial_install_flag === true ? true : false} onChange={this.handleTrueFalse} /> Yes
                            <span>&nbsp;&nbsp;</span>
                            <input type="radio" name="initial_install_flagtemp" value="false" defaultChecked={this.state.initial_install_flag === true ? false : true} onChange={this.handleTrueFalse} /> No
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            Send a Summary Log Message
                        </td>
                        <td colSpan="2">
                            <input type="radio" name="receive_summary_flagtemp" value="true" defaultChecked={this.state.receive_summary_flag === true ? true : false} onChange={this.handleTrueFalse} /> Yes
                            <span>&nbsp;&nbsp;</span>
                            <input type="radio" name="receive_summary_flagtemp" value="false" defaultChecked={this.state.receive_summary_flag === false ? true : false} onChange={this.handleTrueFalse} /> No
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            Send all log messages
                        </td>
                        <td colSpan="2">
                            <input type="radio" name="receive_log_flagtemp" value="true" defaultChecked={this.state.receive_log_flag ? true : false} onChange={this.handleTrueFalse} /> Yes
                            <span>&nbsp;&nbsp;</span>
                            <input type="radio" name="receive_log_flagtemp" value="false" defaultChecked={this.state.receive_log_flag ? false : true} onChange={this.handleTrueFalse} /> No
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            Disable Export?
                        </td>
                        <td colSpan="2">
                            <input type="radio" name="enabled_flagtemp" value="true" defaultChecked={this.state.enabled_flag ? true : false} onChange={this.handleTrueFalse} /> Enabled
                            <span>&nbsp;&nbsp;</span>
                            <input type="radio" name="enabled_flagtemp" value="false" defaultChecked={this.state.enabled_flag ? false : true} onChange={this.handleTrueFalse} /> Disabled
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" id="invisiblefields">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="ftp_connection_type_id"
                                    style={{ display: "none" }}
                                    value={this.state.ftp_connection_type_id} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="deleted_flag"
                                    style={{ display: "none" }}
                                    value={el.deleted_flag} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="export_type_id"
                                    style={{ display: "none" }}
                                    value={el.export_type_id} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="participant_id"
                                    style={{ display: "none" }}
                                    value={el.participant_id} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="export_method_id"
                                    style={{ display: "none" }}
                                    value={el.export_method_id} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="domain"
                                    style={{ display: "none" }}
                                    value={el.domain} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="enabled_flag"
                                    style={{ display: "none" }}
                                    value={this.state.enabled_flag === true ? "true" : "false"} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="receive_log_flag"
                                    style={{ display: "none" }}
                                    value={this.state.receive_log_flag === true ? "true" : "false"} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="receive_summary_flag"
                                    style={{ display: "none" }}
                                    value={this.state.receive_summary_flag === true ? "true" : "false"} />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="initial_install_flag"
                                    style={{ display: "none" }}
                                    value={this.state.initial_install_flag === true ? "true" : "false"} />

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <button
                                className="btn btn-primary btn-block btn-fixed-width"
                                onClick={this.handleCancel}
                            >Cancel</button>
                        </td>
                        <td colSpan="4">&nbsp;</td>
                        <td >
                            <CheckButton
                                className="btn btn-primary btn-block btn-fixed-width"
                                onClick={this.handleSave}
                                ref={c => { this.checkBtn = c; }}
                            >Save</CheckButton>
                        </td>
                    </tr>
                </tbody>
            </table>

        </Form>


    }

    render() {
        let buttons = this.renderButtons();
        let dsp = this.renderDisplay();
        return (
            <div className="updateParticipantDiv">
                {buttons}
                {dsp }
            </div>
            
        );
    }

}
export default ParticipantExports;