import React, { Component } from 'react';
import AuthService from "../services/auth";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-validation/build/form'
import CheckButton from "react-validation/build/button";

//const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
//const toISOStringWithTimezone = date => {
//    const tzOffset = -date.getTimezoneOffset();
//    const diff = tzOffset >= 0 ? '+' : '-';
//    return date.getFullYear() +
//        '-' + pad(date.getMonth() + 1) +
//        '-' + pad(date.getDate()) +
//        'T' + pad(date.getHours()) +
//        ':' + pad(date.getMinutes()) +
//        ':' + pad(date.getSeconds()) +
//        diff + pad(tzOffset / 60) +
//        ':' + pad(tzOffset % 60);
//};

var exportTypes = [
    { exportTypeId: 0, addType: 0, exportName: "Unknown" },
    { exportTypeId: 1, addType: 101, exportName: "API" },
    { exportTypeId: 2, addType: 102, exportName: "FTP" },
    { exportTypeId: 3, addType: 103, exportName: "SFTP" }
];

export class StoreExportTypes extends Component {
    static displayName = StoreExportTypes.name;

    constructor(props) {
        super(props);
        this.state =
        {
            index: -1,
            lastupdatestatus: null,
            currentUser: { username: "" },
            currentStore: props.currentstore,
            currentParticipant: props.currentParticipant,
            displayDate: new Date(),
            export_schedule_id: -1,
            scheduleList: [],
            userReady: false
        }
        this.handleScheduleSection = this.handleScheduleSection.bind(this);
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleAddNewSchedule = this.handleAddNewSchedule.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.setInitialStoreList = this.setInitialStoreList.bind(this);
        this.renderExportOptions = this.renderExportOptions.bind(this);
        this.renderGrid = this.renderGrid.bind(this);
        console.log("StoreExportTypes constructor");
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser || currentUser.loggedin === false) {
            alert("ParticipantUpdate - componentDidMount\r\nLogin expired.  Navigate to home page");
            AuthService.logout();
            this.setState({ currentUser: null, userReady: false });
            //this.props.router.navigate("/");
        }
        else
        {
            this.setState({ currentUser: currentUser, userReady: true })
            this.setInitialStoreList(this.state.currentStore, parseInt(currentUser.callerid));
        }
    }

    setInitialStoreList(storeSettings, callerid) {
        var allParticipants = storeSettings.participants;
        var ourExports = this.state.currentParticipant.exportList;
        var runningSchedules = [];

        var sidx = 0;
        allParticipants.forEach(function (item, index) {
            item.schedules.forEach(function (sitem, sindx) {
                if (sitem.export_schedule_id > 0) {
                    var sd = new Date(Date.parse(sitem.start_date));
                    var sdEntry = runningSchedules.find(x => x.start_date.getHours() === sd.getHours() && x.start_date.getMinutes() === sd.getMinutes());
                    if (typeof sdEntry === "undefined") {
                        var myExports = ourExports.map(function (eitem, eindex) {
                            return {
                                "export_type_id": eitem.export_type_id, "export_method_id": eitem.export_method_id,
                                "disabled": eitem.deleted_flag === "Y" || eitem.enabled_flag === false,
                                "checked": sitem.export_schedule_id === 0 ? false : eitem.export_method_id === sitem.export.export_method_id
                            };
                        });
                        runningSchedules.push({ "sidx": sidx, "start_date": sd, "readonlyflag": callerid !== sitem.export.participant_id, "exportList": myExports, "exports": [] });
                        sdEntry = runningSchedules[sidx];
                        sidx++;
                    }
                    else {
                        if (sdEntry.readonlyflag === false && sdEntry.exports.findIndex(x => x.participant_id !== sitem.export.participant_id) >= 0) sdEntry.readonlyflag = true;
                        var midx = sdEntry.exportList.findIndex(x => x.export_method_id === sitem.export_method_id);
                        if (midx >= 0) sdEntry.exportList[midx].checked = sdEntry.export_schedule_id === 0 ? false : true;
                    }


                    sdEntry.exports.push({
                        "participant_id": item.participant_id,
                        "export_schedule_id": sitem.export_schedule_id,
                        "export_method_id": sitem.export.export_method_id,
                        "enabled_flag": sitem.export.enabled_flag,
                        "deleted_flag": sitem.export.deleted_flag,
                        "originatingSchedule": sitem
                    });

                }
            })
        });
        this.setState({ "scheduleList": runningSchedules });

    }
    handleScheduleSection(e) {
        this.setState({ "export_schedule_id": parseInt(e.target.value,10) });
    }

    handleDateTimeChange(date, sidx) {
        let ids = [...this.state.scheduleList];     // create the copy of state array
        ids[sidx].start_date = date;                  //new value
        this.setState({ ids });            //update the value     
    }

    handleCheckBoxChange(e) {
        let sidx = parseInt(e.target.getAttribute("data-sidx"));
        let export_method_id = parseInt(e.target.value, 10);
        let ids = [...this.state.scheduleList];
        try {
            let eindex = ids[sidx].exportList.findIndex(x => x.export_method_id === export_method_id);
            if (eindex >= 0) {
                ids[sidx].exportList[eindex].checked = e.target.checked;
                this.setState({ "scheduleList": ids });
                return;
            }

        }
        catch (e) {
            alert(e);
        }


    }

    handleAddNewSchedule(e) {
        e.preventDefault();
        let ids = [...this.state.scheduleList];     // create the copy of state array
        var sidx = ids.length;
        var sd = Date.now();
        var ourExports = this.state.currentParticipant.exportList;
        var myExports = ourExports.map(function (eitem, eindex) {
            return {
                "export_type_id": eitem.export_type_id, "export_method_id": eitem.export_method_id,
                "disabled": false,
                "checked": false
            };
        });
        var originatingSchedule = {
            "export_schedule_id": 0, "store_id": this.state.currentStore.store_id, "export_method_id": 0, "start_date": sd, "deleted_flag": "N", "export": null
        };
        ids.push({
            "sidx": sidx, "start_date": sd, "readonlyflag": false,"originatingSchedule": originatingSchedule, "exportList": myExports, "exports": [] });
        this.setState({"scheduleList": ids});            //update the value     
        console.log(ids);
    }

    handleSave(e) {
        e.preventDefault();
        let callerid = parseInt(this.state.currentUser.callerid, 10);
        var currentStore = this.state.currentStore;
        var currentParticipant = this.props.currentParticipant;
        let ids = [...this.state.scheduleList];

        var thisParticipant = currentStore.participants.find(x => x.participant_id === callerid);
        if (typeof thisParticipant === "undefined") { console.log("Unable to find participant_id " + callerid + " in the list of store participants"); return; }

        //Loop through all the checkboxes
        ids.forEach(function (item, idx) {
            item.exportList.forEach(function (eitem, eidx) {
                var exportExists = false;
                var expl = item.exports.find(x => x.export_method_id === eitem.export_method_id);
                //var sdText = toISOStringWithTimezone(new Date(item.start_date));
                if (typeof expl != "undefined") {
                    var sch = thisParticipant.schedules.find(x => x.export_schedule_id === expl.export_schedule_id && x.export.export_method_id === eitem.export_method_id);  //Is this schedule new to us?
                    if (typeof sch !== "undefined") {
                        exportExists = true;
                        sch.start_date = item.start_date;
                        if (!eitem.checked) {
                            sch.deleted_flag = "Y";
                        }
                    }
                }
                if (!exportExists) {
                    if (eitem.checked) {
                        var newSchedule = {
                            "deleted_flag": "N", "export": null, "export_method_id": eitem.export_method_id, "export_schedule_id": 0,
                            "frequency_minutes": 60, "pos_type_id": 0, "retry_interval_minutes": 10, "start_date": item.start_date,
                            "store_id": currentStore.store_id
                        };
                        var exp = currentParticipant.exportList.find(x => x.export_method_id === eitem.export_method_id);
                        if (typeof exp !== "undefined") {
                            newSchedule.export = exp;
                            newSchedule.export_method_id = exp.export_method_id;
                        }
                        thisParticipant.schedules.push(newSchedule);
                    }
                }
                else {
                    if (!eitem.checked) { 
                        var expd = currentParticipant.exportList.find(x => x.export_method_id === eitem.export_method_id);
                        if (typeof expd !== "undefined") {
                            expd.deleted_flag = "Y";
                        }
                    }
                }
            });


        });
        this.setState({ "currentStore": currentStore });
        this.props.parentCallback(thisParticipant.schedules);
    }

    //https://reactdatepicker.com/#example-select-time
    renderExportOptions(sch) {
        var hc = this.handleCheckBoxChange;
        var columns = sch.exportList.map(function (eitem, eindex) {
            //var checked = sch.exports.findIndex(x => x.export_method_id === eitem.export_method_id) >= 0 ? true : false;
            return <td width="80">
                <input type="checkBox" defaultValue={eitem.export_method_id} defaultChecked={eitem.checked} disabled={eitem.disabled}
                    data-compiled={new Date().toString() }
                    data-sidx={sch.sidx} onChange={hc }>
                </input>&nbsp;{exportTypes[eitem.export_type_id].exportName}</td>
        });

        var sd = new Date(sch.start_date);      //Start date is always in UTC time

        var dp = sch.readonlyflag ? <td>{sd.toLocaleString()}</td> :
            <td><DatePicker selected={sd} showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                onChange={(date) => this.handleDateTimeChange(date, sch.sidx)}
                dateFormat="MM/dd/yyyy h:mm aa"></DatePicker></td>;
        return <tr>
            {dp }
            {columns}</tr>
    }

    renderGrid() {

        if (this.state.currentUser.username === "") return (<p>You must login again</p>);
        var reo = this.renderExportOptions;
        var rows = this.state.scheduleList.map(function (item, index) {
            return reo(item);
        });

        return (<div id="buttonsDiv" style={{ float: "left" }}>
            <table cellPadding="5">
                <thead>
                    <tr>
                        <th>Start Date/Time</th>
                        <th colSpan="4">Select Export Method(s)</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                    <tr><td colSpan="2"><button name="btbAddNewSchedule" onClick={this.handleAddNewSchedule} className="btn btn-primary btn-block ">Add new schedule</button></td></tr>
                </tbody>
                </table>
        </div>);
    }
    
    render() {
        let grid = this.renderGrid();
        return (<div>
            <Form ref={c => { this.form = c; }}>
                <div className="buttonsDiv">
                    <table cellPadding="5" >
                        <tbody>
                            <tr>
                                <td>
                                    <CheckButton onClick={this.handleSave}
                                        className="btn btn-primary btn-block btn-fixed-width"
                                        disabled={this.props.updateloading}>
                                        <span>Save Export</span>
                                    </CheckButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="updateDiv">
                    {grid }
                </div>
            </Form>
        </div>);
    }
}
export default StoreExportTypes;