import React, { Component} from 'react';
import AuthService from "../services/auth";
import authHeader from '../services/auth-header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';     //Used to add a paginator to the BootstrapperTable
//import Login from "../components/login";

//https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/about.html
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
//import paginationFactory from 'react-bootstrap-table2-paginator';     //Used to add a paginator to the BootstrapperTable

import './StoreUpdate.css';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

//https://www.npmjs.com/package/react-validation/v/1.4.1
import Form from 'react-validation/build/form'
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";

import StoreContacts from './StoreContacts.js';
import StorePosTypes from './StorePosTypes.js';
import StoreLogs from './StoreLogs.js';
import StoreExportTypes from './StoreExportTypes.js';


//https://www.npmjs.com/package/react-validation
//https://github.com/Lesha-spr/react-validation/blob/master/src/App.js  (Demo)
//https://www.npmjs.com/package/react-tabs

const integeronly = value => {
    if (value && isNaN(value)) return (<div className="alert alert-danger" role="alert">
        This field must be numeric!
    </div>
    );
}

export class StoreUpdate extends Component {
    static displayName = StoreUpdate.name;

    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.integeronly = this.integeronly.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleLogsCallback = this.handleLogsCallback.bind(this);
        this.handleSchedulesCallback = this.handleSchedulesCallback.bind(this);

        this.onChangeSearchAccountID = this.onChangeSearchAccountID.bind(this);
        this.onChangeSearchAccountName = this.onChangeSearchAccountName.bind(this);
        this.onChangeSearchCity = this.onChangeSearchCity.bind(this);
        this.onChangeSearchID = this.onChangeSearchID.bind(this);
        this.onChangeSearchState = this.onChangeSearchState.bind(this);
        this.onChangeSearchStoreName = this.onChangeSearchStoreName.bind(this);
        this.onChangeSearchZip = this.onChangeSearchZip.bind(this);
        this.doUpdate = this.doUpdate.bind(this);

        this.state =
        {
            userReady: false,
            currentUser: { username: "" },
            loading: false,
            searchStoreID: "",
            searchStoreName: "",
            searchAccountID: "",
            searchAccountName: "",
            searchCity: "",
            searchState: "",
            searchZip: "",
            columns: [
                { dataField: 'store_id', sort: true, text: 'Store ID', headerClasses: 'td-10', style: { maxWidth: '80px' } },
                { dataField: 'store_name', sort: true,  text: 'Store Name'},
                { dataField: 'store_status', sort: true, text: 'Status', headerClasses: 'td-10' },
                { dataField: 'addr1', sort: true, text: 'Address' },
                { dataField: 'city', sort: true, text: 'City'},
                { dataField: 'state', sort: true, text: 'State', headerClasses: 'td-10' },
                { dataField: 'zip', sort: true, text: 'Zip', headerClasses: 'td-10' },
                { dataField: 'pp', sort: true,  text: "Patron", headerClasses: 'td-10', filter: textFilter({ defaultValue: '' }) },
                { dataField: 'op', sort: true, text: "Onpoint", headerClasses: 'td-10', filter: textFilter({ defaultValue: '' }) }
            ],
            stores: null,
            selected: null,
            currentstore: null,
            currentParticipant: null,
            currenttab: 0,
            originalstore: null,
            contactErrors: null,
            updateloading: false,
            lastupdatestatus: null

        };
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser || currentUser.loggedin === false) {
            AuthService.logout();
            this.setState({ currentUser: null, userReady: false });
            alert("StoreUpdate - componentDidMount\r\nLogin expired.  Navigate to home page");
            //window.location.reload();
        }
        else {
            this.setState({ currentUser: currentUser, userReady: true })
        }
    }

    integeronly(e) {
        var value = e.target.value;
        if (value && isNaN(value)) {
            this.setState({ message: "This field must be an integer" })
        }
        else { this.setState({ message: null }); }
    }

    onChangeSearchID(e) { this.setState({ searchStoreID: e.target.value }); }
    onChangeSearchStoreName(e) { this.setState({ searchStoreName: e.target.value }); }
    onChangeSearchAccountID(e) { this.setState({ searchAccountID: e.target.value }); }
    onChangeSearchAccountName(e) { this.setState({ searchAccountName: e.target.value }); }
    onChangeSearchCity(e) { this.setState({ searchCity: e.target.value }); }
    onChangeSearchState(e) { this.setState({ searchState: e.target.value }); }
    onChangeSearchZip(e) { this.setState({ searchZip: e.target.value }); }

    //*****  User has entered criteria for the search */
    handleSearch(e) {
        e.preventDefault();
        this.setState({
            message: "",
            currentstore: null,
            currenttab: 0,
            loading: true
        });

        this.form.validateAll();

        let requestOptions =
        {
            method: 'post',
            headers: authHeader(),
            body: JSON.stringify({
                store_id: this.state.searchStoreID === "" ? null : this.state.searchStoreID,
                store_name: this.state.searchStoreName === "" ? null :  this.state.searchStoreName,
                account_id: this.state.searchAccountID === "" ? null : this.state.searchAccountID,
                account_name: this.state.searchAccountName === "" ? null : this.state.searchAccountName,
                city: this.state.searchCity === "" ? null : this.state.searchCity,
                state: this.state.searchState === "" ? null : this.state.searchState,
                zip: this.state.searchZip === "" ? null :  this.state.searchZip
            })
        };
        fetch('/TAClient/FindStores', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    this.setState({ message: response.statusText });
                    if (response.status === 401) {
                        AuthService.logout();
                        alert("Login expired.  Reload from " + window.location.assign() + "/");
                        window.location.href = window.location.assign() + "/";
                    }
                    else throw new Error(response.status);
                }
                else return response.json();
            })
            .then((dobj) => {
                this.setState({ loading: false });
                if (dobj.status) {
                    this.setState({ stores: dobj.stores });
                }
                else {
                    this.setState({
                        message: dobj.status_text,
                        stores: null
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    stores: null,
                    loading: false
                });
            });

        let request2Options =
        {
            method: 'get',
            headers: authHeader()
        };

        fetch('/TAClient/GetParticipant?participant_id=' + this.state.currentUser.callerid, request2Options)
            .then((response) => {
                if (!response.ok) {
                    this.setState({ message: response.statusText });
                    if (response.status === 401) {
                        alert("Login expired.  Reload from " + window.location.host + "/");
                        AuthService.logout();
                        this.props.router.navigate("/");
                    }
                    else throw new Error(response.status);
                }
                else return response.json();
            })
            .then((dobj) => {
                this.setState({ loading: false });
                if (dobj.status) {
                    this.setState({ currentParticipant: dobj, message: "" });
                }
                else {
                    this.setState({
                        message: dobj.status_text,
                        currentParticipant: null
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    currentParticipant: null,
                    loading: false
                });
            });
    }

    //*****  User wants to clear search criteria */
    handleClear(e) {
        e.preventDefault();
        this.setState({
            searchAccountID: "", searchAccountName: "", searchCity: "", searchState: "", searchStoreID: "", searchStoreName: "", searchZip: "",
            currentstore: null
        });
    }

    //**** User selected a store from the list of stores   */
    handleSelect = (childData) => {
        this.setState({ selected: childData, message: "", currentstore: null })
        let requestOptions =
        {
            method: 'post',
            headers: authHeader(),
            body: childData
        };

        fetch('/TAClient/GetStoreSettingsPost', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    this.setState({ message: response.statusText });
                    if (response.status === 401) {
                        AuthService.logout();
                        window.location.reload();
                    }
                    throw new Error(response.status);
                }
                else return response.json();
            })
            .then((dobj) => {
                if (dobj.configurationStatus === "Success") {
                    this.setState({ currentstore: dobj, originalstore: JSON.stringify(dobj), loading: false });
                }
                else {
                    this.setState({
                        message: dobj.configurationStatus,
                        currentstore: null,
                        originalstore: null,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    message: error.message
                });
            });

    }

    //**** User asked to save changes for one POS type */
    handlePOSTypesCallback = (index, values) => {
        var cs = this.state.currentstore;
        this.setState({ lastupdatestatus: ""});
        switch (cs.pos_settings[index].pos_type_id)
        {
            case 1:
                cs.pos_settings[index].passport_settings.source_path = values.passportSourcePath;
                cs.pos_settings[index].passport_settings.passport_domain = values.passportDomain;
                cs.pos_settings[index].passport_settings.passport_username = values.passportUsername;
                cs.pos_settings[index].passport_settings.passport_password = values.passportPassword;
                cs.pos_settings[index].passport_settings.ftp_connection_type = values.passportFTPConnectionType;
                cs.pos_settings[index].passport_settings.ftp_host = values.passportFTPHost;
                //cs.pos_settings[index].passport_settings.ftp_port = values.FieldDoesNotExistOnForm;
                cs.pos_settings[index].passport_settings.ftp_username = values.passportFTPUsername;
                cs.pos_settings[index].passport_settings.ftp_password = values.passportFTPPassword;
                cs.pos_settings[index].passport_settings.days_to_keep = values.passportDaysToKeep;
                cs.pos_settings[index].store_id = values.passportStoreID;
                cs.pos_settings[index].deleted_flag = values.passportDeletedFlag;
                break;
            case 2:
                cs.pos_settings[index].commander_settings.days_to_keep = values.commanderDaysToKeep;
                cs.pos_settings[index].commander_settings.reset_password_interval = values.commanderPasswordInterval;
                cs.pos_settings[index].commander_settings.password_length = values.commanderPasswordLength;
                cs.pos_settings[index].commander_settings.commanderIP = values.commanderIP;
                cs.pos_settings[index].commander_settings.commanderUser = values.commanderUsername;
                cs.pos_settings[index].commander_settings.commanderPass = values.commanderPassword;
                cs.pos_settings[index].store_id = values.commanderStoreID;
                cs.pos_settings[index].deleted_flag = values.commanderDeletedFlag;
                break;
            case 5:
                cs.pos_settings[index].smartdesq_settings.exportInvokePath = values.smartdesqExportInvokePath;
                cs.pos_settings[index].smartdesq_settings.sourceTransactionsLogPath = values.smartdesqSourceTransacionsLogPath;
                cs.pos_settings[index].smartdesq_settings.daysToGoBack = values.smartdesqDaysToGoBack;
                cs.pos_settings[index].smartdesq_settings.days_to_keep = values.smartdesqDaysToKeepSentFiles;
                cs.pos_settings[index].smartdesq_settings.millisecondsToSleepBetweenRequests = values.smartdesqMillisecondsToSleepBetweenRequests;
                cs.pos_settings[index].smartdesq_settings.totalNumberOfRequests = values.smartdesqTotalNumberOfRequests;
                cs.pos_settings[index].store_id = values.smartdesqStoreID;
                cs.pos_settings[index].deleted_flag = values.smartdesqDeletedFlag;
                break;
            case 6:
                debugger;
                cs.pos_settings[index].radiant_settings.source_path = values.radiantSourcePath;
                cs.pos_settings[index].radiant_settings.daily_folders = values.radiantDailyFolders;
                cs.pos_settings[index].radiant_settings.elevated_domain = values.radiantElevatedDomain;
                cs.pos_settings[index].radiant_settings.elevated_username = values.radiantUserName;
                cs.pos_settings[index].radiant_settings.elevated_password = values.radiantPassword;
                cs.pos_settings[index].radiant_settings.days_to_keep = values.radiantDaysToKeep;
                cs.pos_settings[index].store_id = values.radiantStoreID;
                cs.pos_settings[index].deleted_flag = values.radiantDeletedFlag;
                break;

            default:
                break;
        }
        this.setState({ "currentstore": cs });
        this.doUpdate(cs);
    }

    //***** User asked to change a contact */
    handleContactsCallback = (values) => {
        console.log("handelContactsCallback: " + JSON.stringify(values));
        var cs = this.state.currentstore;
        if (cs.primary === null) {
            cs["primary"] = { contact_id: 0, contact_type: 2, first_name: null, last_name: null, title: null, name: null, email: null, phone: 0, phonetext: null };
        }
        if (cs.owner === null) {
            cs["owner"] = { contact_id: 0, contact_type: 2, first_name: null, last_name: null, title: null, name: null, email: null, phone: 0, phonetext: null };
        }
        cs.primary.title = values.primaryTitle;
        cs.primary.contact_type = 2;
        cs.primary.first_name = values.primaryFirstName;
        cs.primary.last_name = values.primaryLastName;
        cs.primary.phone = values.primaryPhoneText == null ? 0 : parseInt(values.primaryPhoneText.replace(/[^0-9]/gi, ""));
        cs.primary.phonetext = values.primaryPhonetext;
        cs.primary.email = values.primaryEmail;

        cs.owner.title = values.ownerTitle;
        cs.owner.contact_type = 1;
        cs.owner.first_name = values.ownerFirstName;
        cs.owner.last_name = values.ownerLastName;
        cs.owner.phone = values.ownerPhoneText == null ? 0 : parseInt(values.ownerPhoneText.replace(/[^0-9]/gi, ""));
        cs.owner.phonetext = values.ownerPhonetext;
        cs.owner.email = values.ownerEmail;

        this.setState({ "currentstore": cs });
        this.doUpdate(cs);
    }

    //***** User asked to search the LOGS for this store */
    handleLogsCallback = (values) => {
        console.log("Find logs with " + JSON.stringify(values));
    };

    //***** User asked to change a schedule entry */
    handleSchedulesCallback = (schedules) => {
        var cs = this.state.currentstore;
        var callerid = parseInt(this.state.currentUser.callerid, 10);
        var pidx = cs.participants.findIndex(x => x.participant_id === callerid);
        if (pidx >= 0) {
            cs.participants[pidx].schedules = schedules;
            this.setState({ "currentstore": cs });

            //****** Make a copy of the store so that we can change the schedule start_date to UTC time */
            var oldText = JSON.stringify(cs);
            var ocs = JSON.parse(oldText);
            ocs.participants[pidx].schedules.forEach(function (sitem, sidx) {
                var sd = new Date(sitem.start_date);
                var offset = sd.getTimezoneOffset() / 60;
                var hours = sd.getHours();
                sd.setHours(hours - offset);
                sitem.start_date = sd;
            });
            this.doUpdate(ocs);
        }
    };

    //***** Call the server to update a store */
    doUpdate(cs) {
        var rst = "Update not performed";
        const requestOptions =
        {
            method: 'post',
            headers: authHeader(),
            body: JSON.stringify(cs)
        };
        this.setState({ updateloading: true });
        fetch('/TAClient/UpsertStore', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        AuthService.logout();
                        return {"status" : false, "statusText": "You must log in again"}
                    }
                    else {
                        return { "status": false, "statusText": "Error submitting update!: " + response.statusText }
                    }
                }
                else return response.json();
            })
            .then((dobj) => {
                rst = dobj.statusText;
                var title = "Store Updated";
                if (rst === "Success") {
                    rst = "Update successful";
                }
                else {
                    title = "Error updating store";
                }
                confirmAlert({
                    title: title,
                    message: rst,
                    buttons: [
                        {
                            label: 'Continue',
                        }
                    ]
                });
            });
    }
    render() {
        const pageOptions = {
            // pageStartIndex: 0,
            sizePerPage: 5,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true
        };
        console.log(JSON.stringify(this.state.currentstore));
        let storeGridData = this.state.stores === null
            ? ""
            : <div>
                <BootstrapTable columns={this.state.columns} data={this.state.stores} keyField='store_id' bootstrap4={true} filter={filterFactory()} headerClasses='tr-sticky' pagination={paginationFactory(pageOptions)} 
                    rowEvents={
                    {
                        onClick: (e, row, rowIndex) => {
                            this.handleSelect(row.store_id);
                        }
                    }
                } />
            </div> 
//            : <SimpleTable columns={this.state.columns} stores={this.state.stores} parentCallback={this.handleSelect} />

        let storeInfoData = (this.state.currentstore === null)
            ? ""
            : <div className='updateDiv'>
                <p>{this.state.currentstore.name} { this.state.currentstore.store_id}</p>
                <p>Store phone: {this.state.currentstore.phonetext}</p>
                <p>Physical address: {this.state.currentstore.physical_address.addr1} {this.state.currentstore.physical_address.city}, {this.state.currentstore.physical_address.state} {this.state.currentstore.physical_address.postal_code}</p>
                <p>Google address: {this.state.currentstore.google_address.addr1} {this.state.currentstore.google_address.city}, {this.state.currentstore.google_address.state} {this.state.currentstore.google_address.postal_code}</p>
                <p>TC Password: {this.state.currentstore.decryptedPassword}</p>
            </div>
        let contactData = (this.state.currentstore !== null)
            ? <StoreContacts currentstore={this.state.currentstore} parentCallback={this.handleContactsCallback} lastupdatestatus={ this.state.lastupdatestatus} />
            : ""

        let posTypeData = (this.state.currentstore !== null)
            ? <StorePosTypes currentstore={this.state.currentstore} parentCallback={this.handlePOSTypesCallback } />
            : ""
        let logData = (this.state.currentstore !== null)
            ? <StoreLogs store_id={this.state.currentstore.store_id} storename={this.state.currentstore.name} levelFilter="" />
            : ""
        let exportTypeData = (this.state.currentstore !== null)
            ? <StoreExportTypes currentstore={this.state.currentstore} currentParticipant={this.state.currentParticipant} parentCallback={this.handleSchedulesCallback} />
        : ""
        let currentStoreData = (this.state.currentstore == null ? "" :
            <Tabs selectedIndex={this.state.currenttab} onSelect={newIndex => this.setState({currenttab : newIndex, lastupdatestatus: ""}) }>

                <TabList>
                    <Tab>Store information</Tab>
                    <Tab>Contacts</Tab>
                    <Tab>Retrieving Data</Tab>
                    <Tab>Sending Data</Tab>
                    <Tab>Store Logs</Tab>
                </TabList>
                    <TabPanel>{storeInfoData }</TabPanel>
                    <TabPanel>{contactData}</TabPanel>
                    <TabPanel>{posTypeData}</TabPanel>
                    <TabPanel>{exportTypeData}</TabPanel>
                    <TabPanel>{logData}</TabPanel>
                </Tabs>);


        return (
            <div>
                <h1 id="tableLabel">Configure Stores</h1>
                <SplitterLayout vertical={true }   >
                    <div>
                        <div className="searchparams">
                            <Form onSubmit={this.handleSearch}
                                ref={c => {
                                    this.form = c;
                                }}>
                                <table cellPadding="5" width="100%">
                                    <tbody>
                                        <tr>
                                            <td valign="top">Store ID:</td>
                                            <td valign="top">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchStoreID"
                                                        value={this.state.searchStoreID}
                                                        onChange={this.onChangeSearchID}
                                                        validations={[integeronly]}
                                                    />
                                                </div>
                                            </td>
                                            <td valign="top">Store Name:</td>
                                            <td valign="top">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchStoreName"
                                                        value={this.state.searchStoreName}
                                                        onChange={this.onChangeSearchStoreName}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">Account ID:</td>
                                            <td>
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchAccountID"
                                                        value={this.state.searchAccountID}
                                                        onChange={this.onChangeSearchAccountID}
                                                        validations={[integeronly]}
                                                    />
                                                </div>
                                            </td>
                                            <td valign="top">Account Name:</td>
                                            <td>
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchAccountName"
                                                        value={this.state.searchAccountName}
                                                        onChange={this.onChangeSearchAccountName}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">City:</td>
                                            <td>
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchCity"
                                                        value={this.state.searchCity}
                                                        onChange={this.onChangeSearchCity}
                                                    />
                                                </div>
                                            </td>
                                            <td valign="top">State:</td>
                                            <td>
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchState"
                                                        value={this.state.searchState}
                                                        onChange={this.onChangeSearchState}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top">Zip Code</td>
                                            <td>
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        name="searchZip"
                                                        value={this.state.searchZip}
                                                        onChange={this.onChangeSearchZip}
                                                        validations={[integeronly]}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" align="center">
                                                <div className="form-group">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        disabled={this.state.loading}
                                                        onClick={this.handleClear}>
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Clear Criteria</span>
                                                    </button>
                                                </div>
                                            </td>
                                            <td colSpan="2" align="center">
                                                <div className="form-group">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        disabled={this.state.loading}>
                                                        {this.state.loading && (
                                                            <span className="spinner-border spinner-border-sm"></span>
                                                        )}
                                                        <span>Find Stores</span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>


                                {this.state.message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton
                                    style={{ display: "none" }}
                                    ref={c => { this.checkBtn = c; }}
                                />
                            </Form>
                        </div>
                        <div className="storeinfo">
                            {storeGridData}
                        </div>

                    </div>
                    <div className="storeUpdatePanel">
                        {currentStoreData}
                    </div>
                </SplitterLayout>
            </div>
        );
    }
}
export default StoreUpdate;